.button {
  position: fixed;
  width: 0%;
  left: 30px;
  bottom: 70px;
  height: 20px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  color: white;
  filter: drop-shadow(0 0 10px rgb(34, 197, 94));
}

.button:hover {
  font-size: 3.5rem;
}
